/* eslint-disable */
<template>
  <div>
    <title-nav :title="'강좌 등록/수정'" :nav="'강좌관리 / 등록/수정'" />
    <b-container fluid>
      <div class="card">
        <div class="card-header">
          <lecture-select
            :isSearch="false"
            :cate1="cate1"
            :cate2="cate2"
            :cate3="cate3"
            @updateOpt1="updateOpt1"
            @updateOpt2="updateOpt2"
            @updateOpt3="updateOpt3"
            ref="lectureSelect"
          />
        </div>
        <b-form v-on:submit.prevent>
          <div class="card-body">
            <b-row>
              <b-col>
                <b-form-group
                  id="input-group-1"
                  label="강좌 번호"
                  label-for="input-lecture-id"
                  description="번호를 입력해주세요"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      ><b-input-group-text>A</b-input-group-text
                      ><b-input-group-text style="color: #e83e8c ">{{ cate1 }}</b-input-group-text>
                      <b-input-group-text style="color:#ffc107 ">{{ cate2 }}</b-input-group-text>
                      <b-input-group-text style="color:#28a745 ">{{
                        cate3
                      }}</b-input-group-text></b-input-group-prepend
                    >
                    <b-form-input
                      id="input-lecture-id"
                      required
                      type="text"
                      maxlength="3"
                      placeholder="### (3자리)"
                      :state="is_validated_lc_no"
                      v-model="lecture_no"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button @click.prevent="checkDup">중복검사</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  id="input-group-11"
                  label="플레이 순서"
                  label-for="input-lecture-id-1"
                  description="순서를 입력해주세요"
                >
                  <b-form-input
                    id="input-lecture-id-1"
                    type="number"
                    maxlength="3"
                    v-model="play_order"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-card no-body>
              <b-row style="margin-left:0">
                <b-col class="card-body" cols="6">
                  <div>
                    <b-form-checkbox v-model="file_checked" name="check-button" switch>
                    </b-form-checkbox>
                  </div>
                  <b-form-group
                    id="input-group-2"
                    label="동영상"
                    label-for="input-video-path"
                    description="동영상을 선택하세요"
                    v-if="!file_checked"
                  >
                    <b-form-file
                      id="input-video-path"
                      accept=".mp4"
                      ref="video_file"
                      @input="handleVodInput"
                      :state="is_validated_vod"
                      required
                    ></b-form-file>
                  </b-form-group>
                  <b-form-group
                    id="input-group-2-2"
                    label="동영상"
                    label-for="input-video-path"
                    description="동영상 Path를 입력후 적용을 클릭하세요"
                    v-else
                  >
                    <b-input-group>
                      <b-form-input
                        id="input-group-2-2"
                        type="text"
                        v-model="file_name"
                        placeholder="/yyyyMM/파일명.mp4"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button variant="outline-success" v-on:click="setVodInput">적용</b-button>
                      </b-input-group-append></b-input-group
                    >
                  </b-form-group>
                  <div>
                    <b-card-text>{{ this.play_second }}초</b-card-text>
                    <b-link
                      :href="videoUrl + '?play=' + vKey"
                      target="_blank"
                      class="card-link"
                      style="font-size:0.7rem;line-height:0.7rem"
                      ><p>{{ videoUrl + '?play=' + vKey }}</p></b-link
                    >
                  </div>
                </b-col>
                <b-col id="videoContainer" style="height:270px" class="">
                  <video
                    ref="videoPlayer"
                    style="width:480px;height:270px;"
                    controls
                    class="card-img-right float-right"
                  ></video>
                  <div id="spinner" style="" v-if="isVideoReady">
                    <b-spinner label="Spinning" style="position:absolute;left:40%;"></b-spinner>
                  </div>
                </b-col>
              </b-row>
            </b-card>

            <b-card :img-src="mThumbnail_path" img-alt="Card image" img-right>
              <b-card-text>
                <b-form-group
                  id="input-group-3"
                  label="썸네일"
                  label-for="input-thumbnail-path"
                  description="PNG 형식의 썸네일을 선택하세요"
                >
                  <b-form-file
                    id="input-thumbnail-path"
                    accept="image/png"
                    ref="image_file"
                    @change="previewImage"
                    required
                  ></b-form-file>
                </b-form-group>
                <b-link
                  :href="mThumbnail_path"
                  target="_blank"
                  class="card-link"
                  style="font-size:0.7rem"
                  >{{ mThumbnail_path }}</b-link
                >
              </b-card-text>
            </b-card>

            <b-row>
              <b-col cols="9">
                <b-form-group id="input-group-3" label="강좌 제목" label-for="input-title">
                  <b-form-input
                    id="input-title"
                    type="text"
                    v-model="lc_title"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="input-group-44" label="강사명" label-for="input-lecturer">
                  <b-form-input
                    id="input-lecturer"
                    type="text"
                    v-model="lecturer_name"
                    placeholder="무료 또는 메인 등록시 입력"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group id="input-group-4" label="강좌 설명" label-for="input-description">
              <b-form-textarea
                v-model="lc_desc"
                id="input-description"
                rows="3"
                max-rows="6"
                required
              ></b-form-textarea>
              <!-- <editor ref="content_editor" /> -->
            </b-form-group>

            <b-form-group id="input-group-5" label="해시 태그" label-for="input-hashtag">
              <b-form-tags
                input-id="tags-basic"
                separator="[' ', ',', ';']"
                placeholder="Enter new tags separated by space, comma or semicolon"
                v-model="tags"
              ></b-form-tags>
            </b-form-group>

            <b-row>
              <b-col>
                <b-form-group
                  label="유료/무료 구분"
                  v-slot="{ ariaDescribedby }"
                  label-for="some-radio"
                >
                  <b-form-radio
                    v-model="is_cost"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="Y"
                    >유료</b-form-radio
                  >
                  <b-form-radio
                    v-model="is_cost"
                    :aria-describedby="ariaDescribedby"
                    name="some-radios"
                    value="N"
                    >무료</b-form-radio
                  >
                </b-form-group> </b-col
              ><b-col>
                <b-form-group
                  label="공개/비공개"
                  v-slot="{ ariaDescribedby }"
                  label-for="close-radio"
                >
                  <b-form-radio
                    v-model="is_close"
                    :aria-describedby="ariaDescribedby"
                    name="close-radios"
                    value="Y"
                    >비공개</b-form-radio
                  >
                  <b-form-radio
                    v-model="is_close"
                    :aria-describedby="ariaDescribedby"
                    name="close-radios"
                    value="N"
                    >공개</b-form-radio
                  >
                </b-form-group></b-col
              >
            </b-row>
          </div>
          <div class="card-footer">
            <b-button class="m-1" variant="dark" @click.prevent="clear"> 취소</b-button>
            <b-button class="m-1" variant="primary" @click.prevent="createLecture(isEditMode)">{{
              isEditMode ? '수정' : '등록'
            }}</b-button>
          </div>
        </b-form>
      </div>
    </b-container>
  </div>
</template>

<script>
import LectureSelect from '../util/LectureSelect'
import {
  checkLectureNum,
  upLoadLecture,
  upLoadVideo,
  uploadImage,
  getLectureInfo,
  getVkey
} from '../../api/lecture'

export default {
  name: 'Edit',
  components: { LectureSelect },
  data() {
    return {
      isVideoReady: false,
      isEditMode: false,

      mThumbnail_path: null,
      url_key: null,

      lc_sn: this.$route.query.lc_no,
      ca_sn: 0,
      is_validated_lc_no: false,
      lecture_no: '',
      cate1: '',
      cate2: '',
      cate3: '',

      lc_title: '',
      lc_desc: '',
      lecturer_name: '',
      tags: [],
      is_cost: 'Y',
      is_close: 'N',
      play_order: null,

      is_validated_vod: false,
      vKey: '',
      videoUrl: '',
      videoFullUrl: '',
      file_name: '',
      play_second: 0,
      file_checked: false // 직접 입력 true
    }
  },
  methods: {
    clear() {
      location.href = '/edit'
    },
    settingVideo: function(videoSrc) {
      var video = this.$refs.videoPlayer
      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = videoSrc
        //
        // If no native HLS support, check if HLS.js is supported
        //
      } else if (Hls.isSupported()) {
        var hls = new Hls()
        console.warn(videoSrc)
        hls.loadSource(videoSrc)
        hls.attachMedia(video)
      }
    },
    async checkDup() {
      if (this.lecture_no.length != 3) {
        alert('3자리 입력 필수')
        return
      }
      if (this.cate1.length != 2 || this.cate2.length != 2 || this.cate3.length != 2) {
        alert('카테고리를 선택하세요')
        return
      }
      var lc_no = `A${this.cate1}${this.cate2}${this.cate3}${this.lecture_no}`

      const resp = await checkLectureNum(lc_no)
      if (resp.code === '0000') {
        this.is_validated_lc_no = true
      } else {
        this.is_validated_lc_no = false
        alert(resp.message)
      }
    },
    startSpinner() {
      this.isVideoReady = true
    },
    stopSpinner() {
      this.isVideoReady = false
    },
    async handleVodInput() {
      this.startSpinner()

      this.play_second = await this.getDuration(this.$refs.video_file.files[0])
      var file = this.$refs.video_file.files[0]
      var frmData = new FormData()
      frmData.append('file', file)
      if (file) {
        this.videoUrl = null
        try {
          const _resp = await upLoadVideo(frmData)

          this.vKey = _resp.data.vKey
          this.file_name = _resp.data.file_name

          if (!_resp.data.vKey) {
            alert(`동영상(vKey)가 없습니다.`)
            return
          }

          this.videoUrl = `${_resp.data.file_path}`
          this.videoFullUrl = `${_resp.data.file_path}?play=${this.vKey}`

          setTimeout(() => {
            this.settingVideo(`${_resp.data.file_path}?play=${this.vKey}`)
            this.is_validated_vod = true
          }, 300)
        } catch (error) {
          this.is_validated_vod = false
          console.error(`error, `, error)
        }
      }

      this.stopSpinner()
    },
    async setVodInput() {
      if (!this.vKey) {
        const { code, data } = await getVkey()
        if (code != '0000') {
          alert(`동영상(vKey) 로딩에 실패하여 미리보기가 불가능합니다.`)
        }
        this.vKey = data.vKey
      }
      this.videoUrl = `https://vod.fmschool.co.kr:8443/vod/mp4:mp4${this.file_name}/playlist.m3u8`
      this.videoFullUrl = `${this.videoUrl}?play=${this.vKey}`
      setTimeout(async () => {
        this.settingVideo(this.videoFullUrl)
        this.is_validated_vod = true
      }, 300)
    },
    previewImage(evt) {
      if (evt.target.files && evt.target.files[0]) {
        var frmData = new FormData()
        var file = evt.target.files[0]
        frmData.append('file', file)
        uploadImage(frmData)
          .then(resp => {
            this.mThumbnail_path = resp.data.url_path
            this.url_key = resp.data.url_key
          })
          .catch(err => {
            console.error(err)
            alert(`동영상 등록 실패${err}`)
          })
      } else {
        this.mThumbnail_path = null
        this.url_key = null
      }
    },
    async getDuration(file, url) {
      if (file) url = URL.createObjectURL(file)

      return new Promise(resolve => {
        const audio = document.createElement('audio')
        audio.muted = true
        const source = document.createElement('source')
        source.src = url //--> blob URL
        audio.preload = 'metadata'
        audio.appendChild(source)
        audio.onloadedmetadata = function() {
          resolve(audio.duration)
        }
      })
    },
    createLecture(isEditMode) {
      if (!this.is_validated_lc_no && !isEditMode) {
        alert(`강좌 번호를 입력/중복검사 하세요`)
        return
      }
      if ((!this.is_validated_vod && !isEditMode) || this.play_second == 0) {
        alert('동영상을 등록하세요')
        return
      }
      if (!this.mThumbnail_path) {
        alert('썸네일을 등록하세요')
        return
      }
      if (!this.lc_title) {
        alert(`강좌명을 입력하세요`)
        return
      }
      if (!this.lc_desc) {
        alert(`강좌 내용을 입력하세요`)
        return
      }
      if (!this.tags) {
        alert('해쉬태그를 추가하세요')
        return
      }

      var lc_no = `A${this.cate1}${this.cate2}${this.cate3}${this.lecture_no}`
      var tags = this.tags.join(' ')
      var lc_sn = this.isEditMode ? this.lc_sn : ''

      upLoadLecture(
        lc_no,
        this.ca_sn,
        this.videoUrl,
        this.mThumbnail_path,
        this.lc_desc,
        this.play_second,
        this.lc_title,
        tags,
        this.is_cost,
        lc_sn,
        this.is_close,
        this.file_name,
        this.url_key,
        this.play_order,
        this.lecturer_name
      )
        .then(_resp => {
          if (_resp.code !== '0000') {
            alert(`등록 실패: ${_resp.message}`)
          } else {
            if (isEditMode) {
              alert('수정되었습니다.')
              this.$router.go(0)
            } else {
              alert('등록되었습니다.')
              //this.$router.push({ path: 'edit', query: { lc_no: lc_no } })
              this.$router.go(0)
            }
          }
        })
        .catch(_err => {
          console.log(_err)
        })
    },
    updateOpt1(data) {
      this.cate1 = data.value
    },
    updateOpt2(data) {
      this.cate2 = data.value
    },
    updateOpt3(data) {
      console.log('data', data)
      this.cate3 = data.value
      this.ca_sn = data.id
      this.is_validated_lc_no = false
    }
  },
  async created() {
    if (this.$route.query.lc_no) {
      this.isEditMode = true
      const resp = await getLectureInfo(this.$route.query.lc_no)
      if (resp.code == '0000') {
        const data = resp.data

        this.videoUrl = data.file_path
        this.file_name = data.file_name
        this.play_second = data.play_second
        this.vKey = data.vKey

        this.mThumbnail_path = data.thumb_url
        this.url_key = data.thumb_key

        this.lecturer_name = data.lecturer_name
        this.lc_desc = data.content
        this.lc_title = data.title
        this.play_order = data.play_order
        this.is_cost = data.is_cost
        this.is_close = data.is_close

        this.lc_sn = data.lc_sn
        this.ca_sn = data.cate3

        this.cate1 = data.lc_no.substring(1, 3)
        this.cate2 = data.lc_no.substring(3, 5)
        this.cate3 = data.lc_no.substring(5, 7)
        this.lecture_no = data.lc_no.substring(7, 10)
        this.$refs.lectureSelect.callEditCategory()

        data.tag?.split(',').map(tag => {
          this.tags.push(tag)
        })

        console.log(this.videoUrl + '?play=' + this.vKey)
        setTimeout(() => {
          this.settingVideo(this.videoUrl + '?play=' + this.vKey)
        }, 500)
      }
    }
  },
  mounted() {
    // 동영상 주소 직접 입력시 재생시간 업데이트
    this.$refs.videoPlayer.addEventListener('loadedmetadata', () => {
      if (this.file_checked === true) {
        console.log(this.$refs.videoPlayer.duration)
        this.play_second = this.$refs.videoPlayer.duration
      }
    })
  }
}
</script>

<style>
@import 'https://unpkg.com/vue-plyr@latest/dist/vue-plyr.css';
</style>

<style scoped>
.vjs-custom-skin /deep/ .video-js {
  height: 270px;
  width: 480px;
}
</style>
